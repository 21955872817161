<template>
  <div :style="this['events/handleMinHeight']"></div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  mounted() {
    this.logout();
  },
  beforeDestroy() {
    this.$root.renewToken();
  },
  computed: { ...mapState([]), ...mapGetters(["events/handleMinHeight"]) },
  methods: {
    async logout() {
      await this.$store.dispatch("account/logout", { vm: this });
    },
  },
};
</script>
